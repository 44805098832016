import InlineImage from "../../../../src/components/blog/InlineImage";
import ImageTrifecta from "../../../../src/components/blog/ImageTrifecta";
import InlineGrid from "../../../../src/components/blog/InlineGrid";
import Spotify from "../../../../src/components/blog/Spotify";
import Youtube from "../../../../src/components/blog/Youtube";
import React from 'react';
export default {
  InlineImage,
  ImageTrifecta,
  InlineGrid,
  Spotify,
  Youtube,
  React
};