module.exports = [{
      plugin: require('/Users/eslawski/source/personal-website/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/eslawski/source/personal-website/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-prismjs"}]},
    },{
      plugin: require('/Users/eslawski/source/personal-website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
